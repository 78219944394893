// lumalier

$(document).ready(function(){
	

$(document).foundation();

$('#products').click(function(){
	$('.products.nav-level2').addClass('active');
	return false;
});

$('.close-button.subnav').click(function(){
	$('.nav-level2').removeClass('active');
});

$('#mobile-menu').click(function(){
	$('.mobile-nav-wrap').toggle(300);	
});

$('.hasSubNav').click(function(){
	$('> ul', this).toggle(300);	
});

$('.type-filter').change(function(){
	
	var type = $('.type-filter:checked').val();
	if (type === "all") {
		$('.product-sub').show();
		
	} else {
		type = "."+type;
		$('.product-sub').hide();
		$(type).show();
	}

});

//product detail tabs

$('.tab-list').click(function(){
	$('.tab-list').removeClass('active');
	$(this).addClass('active');
	var container = "." + $(this).data('index');
	$('.detail-block').hide();
	$(container).show();
});

// learn tabs

$('.tab').click(function(){
	var theTab = $(this).data('tab');
	$('.tab').removeClass('current');
	$(this).addClass('current');
	$('.tab-section').hide().removeClass('current');
	$(theTab).show();	
});

});
